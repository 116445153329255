import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { ButtonLink } from '@naf/button-link';
import type { SanityBlock } from '@sanity/client';
import { ProductType } from '@dtp/membership-service-types';
import { actions } from '../../../redux/modules/myMembership';
import useSelector from '../../../redux/typedHooks';
import { cloudinaryImage } from '../../../utils/imageUrl';
import { useResponsiveImageSize } from '../../../hooks/useResponsiveImageSize';
import { useWindowLocation } from '../../../hooks/useWindowLocation';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import BlockContent from '../../../components/block-content/BlockContent';
import S from './styles';
import { QuoteBlock } from '../../../components/block-content/components/types/components/Quote';
import { AccordionBlock } from '../../../components/block-content/components/types/components/Accordion';
import { CampaignBlock } from '../../../components/block-content/components/types/components/CampaignBlock';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { ProductArticleType } from '../../../../../types/articleType';
import { NotFound } from '../../errors/NotFound';
import FamilyIllustration from '../../../../assets/images/FamilyIllustration.svg?url';
import { actions as idpActions } from '../../../redux/modules/internationalDrivingPermit';
import { isInternalLink } from '../../../lib/isInternalLink';
import BenefitsList from '../../../components/benefits-list/BenefitsList';
import { useDocument } from '../../../hooks/useDocument';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';

const slug = 'internasjonalt-forerkort';

export const IdpArticle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const application = useSelector((state) => state.application);
  const { simpleToken, richToken } = useAuth0Token();
  const [didFetchProducts, setDidFetchProducts] = useState<boolean>(false);
  const [didFetchMemberPrice, setDidFetchMemberPrice] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductType>();
  const [isLoggedin, setIsLoggedin] = useState<boolean>(false);
  const { loginWithRedirect } = useAuth0();
  const baseUrl = useSelector((state) => state.application.baseUrl);

  const handleLink = (url: string | undefined) => {
    const { relativeLink } = isInternalLink(baseUrl, url);
    if (relativeLink) {
      history.push(relativeLink || '');
    } else {
      window.open(url);
    }
  };

  const products = useSelector((state) => state.internationalDrivingPermit.data.eligibleProducts || null);

  useEffect(() => {
    if (!products && !didFetchProducts) {
      dispatch(idpActions.getInternationalDrivingPermit.request(simpleToken));
      setDidFetchProducts(true);
    }
    if (!didFetchMemberPrice && isLoggedin && simpleToken) {
      dispatch(idpActions.getInternationalDrivingPermit.request(simpleToken));
      setDidFetchMemberPrice(true);
      setDidFetchProducts(true);
    }
  }, [didFetchMemberPrice, didFetchProducts, products, simpleToken, isLoggedin, dispatch]);

  const {
    customerInformation,
    customerInformation: {
      meta: { fetchState },
    },
  } = useSelector((state) => state.myMembership);

  const responsiveImagesSize = useResponsiveImageSize(352);

  const windowPath = useWindowLocation();
  const bodyTextRef = useRef<HTMLDivElement>(null);
  const [bodyTextHeight, setbodyTextHeight] = useState(0);
  const {
    data: productArticle,
    meta: { isUpdating: isUpdatingArticle },
  } = useDocument(slug, simpleToken) as {
    data: ProductArticleType;
    meta: {
      isUpdating?: boolean;
      fetchedAt?: string;
      isLoggedin?: boolean;
    };
  };

  useEffect(() => {
    if (products && productArticle && productArticle.products && productArticle.products[0]) {
      setProduct(products[productArticle.products[0].productId]);
    } else setProduct(undefined);
  }, [products, productArticle]);

  useEffect(() => {
    if (richToken && fetchState === 'initial') {
      dispatch(actions.getCustomerInformation.request(richToken));
    }
  }, [richToken, dispatch, fetchState]);

  useEffect(() => {
    if (typeof customerInformation?.data?.membership !== 'undefined' && fetchState === 'success') {
      setIsLoggedin(true);
    } else setIsLoggedin(false);
  }, [customerInformation, fetchState]);

  useEffect(() => {
    if (typeof productArticle !== 'undefined') {
      let newHeight = bodyTextRef ? bodyTextRef?.current?.clientHeight : 0;
      // Set minimum of 350px for height if text is defined
      if (newHeight && newHeight < 350) newHeight = 350;

      if (newHeight) setbodyTextHeight(newHeight);
    }
  }, [productArticle]);

  if (productArticle) {
    const { name, header, body, _id, image, relatedProduct, productSummary, quote, campaignBlock, seoConfig } =
      productArticle;

    const seoTitle = seoConfig?.title ? seoConfig.title : name;

    const imageWidth = bodyTextHeight ? null : responsiveImagesSize.width;
    const imageHeight = bodyTextHeight || undefined;
    const mainImage =
      (image &&
        image.publicId &&
        (imageWidth || imageHeight) &&
        cloudinaryImage(image.publicId, application, imageWidth, imageHeight)) ||
      undefined;

    const schemaImage = image && image.publicId && cloudinaryImage(image.publicId, application, 1200, 630);

    return (
      <LayoutWithMainContent
        title={seoTitle}
        description={seoConfig?.introduction}
        imgUrl={schemaImage || ''}
        url={windowPath}
        gtmArgs={{
          page_type: 'ProductArticlePage',
          pageCategory: `medlemskap/${slug}`,
          contentId: _id,
        }}
        seoTitle={seoTitle}
        seoDescription={seoConfig?.introduction}
        internalSearchMetaData={{
          cardType: InternalSearchCardType.Product,
          product: {
            title: 'Internasjonalt førerkort',
            price: product?.productAppPrice['1']?.itemTotalPrice?.toFixed(10),
          },
        }}
      >
        <S.Wrapper>
          <Grid>
            <GridCol s="12" m="12" l="12" xl="12">
              <BreadCrumb />
            </GridCol>
            <S.RelativeGridRow>
              <S.HeaderCol s="12" m="12" l="6" xl="6">
                <Text tag="h1" variant={TextVariant.Header1}>
                  {header || name}
                </Text>
                {product && (
                  <S.ProductPrice>
                    <Text variant={TextVariant.Header3}>{`${isLoggedin ? 'Medlemspris: ' : 'Ikke-medlemmer '} ${
                      product?.productAppPrice['1'].itemTotalPrice
                    },-`}</Text>
                    <Text>Pris inkl. porto kr 30.</Text>
                  </S.ProductPrice>
                )}
                {relatedProduct && (
                  <>
                    {relatedProduct.productHeaderText && (
                      <Text tag="h3" variant={TextVariant.Header3}>
                        {relatedProduct.productHeaderText}
                      </Text>
                    )}
                    {relatedProduct.productText && (
                      <Text variant={TextVariant.ArticleText}>{relatedProduct.productText}</Text>
                    )}
                    <S.ButtonContainer>
                      {relatedProduct.ctaButtonLink?.url &&
                        relatedProduct.ctaButtonLink?.text &&
                        (isInternalLink(baseUrl, relatedProduct.ctaButtonLink?.url) ? (
                          <S.StyledButton onClick={() => handleLink(relatedProduct.ctaButtonLink?.url)}>
                            {relatedProduct.ctaButtonLink.text}
                          </S.StyledButton>
                        ) : (
                          <S.StyledButton href={relatedProduct.ctaButtonLink?.url}>
                            {relatedProduct.ctaButtonLink.text}
                          </S.StyledButton>
                        ))}
                      {relatedProduct.ctaSecondaryButtonLink &&
                        (isInternalLink(baseUrl, relatedProduct.ctaSecondaryButtonLink?.url) ? (
                          <S.StyledButton
                            onClick={() => handleLink(relatedProduct.ctaSecondaryButtonLink?.url)}
                            variant="outline"
                          >
                            {relatedProduct.ctaSecondaryButtonLink.text}
                          </S.StyledButton>
                        ) : (
                          <S.StyledButton href={relatedProduct.ctaSecondaryButtonLink?.url} variant="outline">
                            {relatedProduct.ctaSecondaryButtonLink.text}
                          </S.StyledButton>
                        ))}
                    </S.ButtonContainer>
                  </>
                )}
              </S.HeaderCol>
              {image && (
                <S.MainImage>
                  <img src={mainImage} alt={image?.altText} />
                </S.MainImage>
              )}
            </S.RelativeGridRow>
            {seoConfig && (
              <S.IngressCol s="12" m="12" l="7" xl="7">
                {seoConfig.introduction}
              </S.IngressCol>
            )}
            {!isLoggedin && (
              <S.LoginCol s="12" m="12" l="4" xl="4">
                <img src={FamilyIllustration} alt="Illustrasjon familie" />
                <Text variant={TextVariant.Header2}>Er du allerede medlem?</Text>
                <ButtonLink
                  text="Logg inn for å få medlemsfordel"
                  onClick={() => loginWithRedirect({ appState: { returnTo: `/${slug}` } })}
                />
              </S.LoginCol>
            )}
            <S.SpacedGridRow>
              <GridCol s="12" m="12" l="8" xl="8">
                {body && (
                  <S.Body ref={bodyTextRef}>
                    <BlockContent value={body} />
                  </S.Body>
                )}
              </GridCol>
            </S.SpacedGridRow>
            {productSummary && productSummary.summarylist && productSummary.summarylist.length > 0 && (
              <S.SpacedGridRow>
                <GridCol s="12" m="12" l="8" xl="8">
                  <S.ColoredBox>
                    {productSummary.header && (
                      <Text tag="h2" variant={TextVariant.Header2}>
                        {productSummary.header}
                      </Text>
                    )}
                    {productSummary.summarylist.map(
                      (accordion: { text: SanityBlock[]; title: string; type: string }) => (
                        <AccordionBlock key={accordion.title} value={accordion} />
                      ),
                    )}
                  </S.ColoredBox>
                </GridCol>
              </S.SpacedGridRow>
            )}
            {quote && quote.text && (
              <S.SpacedGridRow>
                <S.QuoteCol s="12" m="12" l="12" xl="12">
                  <QuoteBlock value={quote} />
                </S.QuoteCol>
              </S.SpacedGridRow>
            )}
            {campaignBlock && (
              <GridCol s="12" m="12" l="12" xl={campaignBlock.illustration ? '12' : '11'}>
                <CampaignBlock value={campaignBlock} />
              </GridCol>
            )}
            {productArticle.membershipBenefits && (
              <GridCol s="12" m="12" l="12" xl="12">
                <S.ReadMoreWrapper>
                  <Text variant={TextVariant.Header2}>Medlemsfordeler</Text>
                  <ButtonLink text="Se alle" onClick={() => history.push('/medlemskap/medlemsfordeler')} />
                </S.ReadMoreWrapper>
                <BenefitsList benefitItems={productArticle.membershipBenefits} />
              </GridCol>
            )}
          </Grid>
        </S.Wrapper>
      </LayoutWithMainContent>
    );
  }
  if (!isUpdatingArticle) return <NotFound />;
  return null;
};
